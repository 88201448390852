import React from "react";
import { withPrefix } from "gatsby-link";
import "./guard.less";
import SceneDetail from "./guard/_scene_detail";
import ImageBlueprint from "./img/guard-blueprint-transparent.png";
import Layout from "../components/layout"


const scenes = [
  {
    key: "exhibition",
    title: "會展簽到",
    desc: "天機1號服務機器人集成多種身份認證技術",
    o: "#detail-exhibition",
    detail: `天機1號服務機器人集成多種身份認證技術， 並內置自有的Singou Encounter簽到雲平台， 將人工智能和互聯網技術融入`
  },
  {
    key: "hotel",
    title: "酒店",
    o: "#detail-hotel",
    desc: "天機1號服務機器人有入住退房、給客人送物、引領帶路、信息諮詢等功能",
    detail: `面向酒店行業開發了天機1號酒店服務機器 人，可以接管酒店內可學習複製性工作，如 入住退房、給客人送物、引領帶路、信息諮 詢等，`
  },
  {
    key: "property",
    title: "物業安防",
    desc: "可應用於物業的日常巡邏、安保監控、業主服務等",
    o: "#detail-property",
    detail: `天機1號物業服務機器人,深度 融合機器學習、物聯網、智慧硬體等先進技 術，具備人臉動態識別、語音聊天、`
  },
  {
    key: "school",
    title: "學校考勤",
    desc: "可應用於學校宿舍、課室及企業的日常考勤簽到",
    o: "#detail-school",
    detail: `天機1號服務機器人集成多種身份認證技術，包括人臉識別、RFID打卡、二維碼動態掃描，可應用於學校宿舍、課室及企業的日常考勤簽到、互動交流`
  }
  // {
  //     'key': 'security', 'title': '安防' ,
  //     'desc': 'Lorem ipsum dolor sit amet, consectetur adipisicing elit' ,"o":"#a2"
  // },
];
const GuardPage = () => (
  <Layout>
  <div id="main-wrap">
    {/*   Main Wrap  */}
    {/*   Page Content  */}
    <div id="page-content" className="header-static">
      {/*   Slider  */}
      <div id="flexslider" className="fullpage-wrap small">
        <ul className="slides">
          <li className="hero-slide-guard" />
        </ul>
      </div>
      {/*   END Slider  */}
      {/*   Home Wrap  */}
      <div id="home-wrap" className="content-section fullpage-wrap">
        {/* Section Scene Icon */}
        <div className="light-background">
          <div className="container">
            <div className="row no-margin padding-onlytop-lg">
              <div className="col-md-12 padding-leftright-null text-center">
                <h3 className="big margin-bottom-small"> 服務機器人 </h3>
                <h3 className="medium"> 應用場景 </h3>
              </div>
              <div className="col-md-12 text" id="scene">
                {/* Single Scene */}
                {scenes.map(scene => (
                  <div data-target={scene.o} className="col-sm-3 single-scene">
                    <article>
                      <img
                        className="image"
                        src={withPrefix(
                          "/assets/img/icon-scene-" + scene.key + ".jpg"
                        )}
                        alt=""
                      />
                      <div className="content">
                        <h5 className="">{scene.title}</h5>
                        <p className="small padding-onlytop-sm">{scene.desc}</p>
                      </div>
                    </article>
                  </div>
                ))}
                {/* END Single Scene */}
              </div>
            </div>
            <div id="scene_sep" className="row no-margin">
              <div className="col-md-12 padding-leftright-null">
                <div className="text padding-onlytop-sm padding-onlybottom-sm padding-md-top-null" />
              </div>
            </div>
          </div>
        </div>
        {/* END Section Scene Icon */}
        <div>{scenes.map(scene => SceneDetail(scene))}</div>
        <div className="row no-margin padding-md">
          <div className="col-md-12 padding-leftright-null text-center">
            <h3 className="secondary-color big margin-bottom-small">
              技術規格
            </h3>
          </div>

          <div className="col-md-4">
            <div className=" padding-topbottom-null ">
              <div className="padding-onlytop-sm">
                <p className="margin-bottom margin-md-bottom-null">
                  <table className="no-border">
                    <tr>
                      <td width="40%" className="dd secondary-color">
                        尺 寸 : <br />
                         <br />
                         <br />
                        重 量 :  <br />
                        顏 色 : <br />
                        材 質 :<br />
                        電 池 : <br />
                        屏 幕 : <br />
                        攝像頭 : <br />
                        揚聲器 : <br />
                        行 動 : <br />
                        網 絡 : <br />
                        身份識別: <br />
                      </td>
                      <td>
                        1650（H） <br /> × 900(D) <br /> × 800(W) <br />
                        65KG <br />
                        各色可選 <br />
                        塑料+金屬 <br />
                        160AH <br />
                        7.9寸觸控屏 <br />
                        1200萬高清攝像頭×2 <br />
                        立體聲喇叭 <br />
                        越野履帶車 <br />
                        Wi-Fi 2.4G/5G<br />人臉識別、智能卡識別、二維碼等
                      </td>
                    </tr>
                  </table>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 ">
            <div className=" padding-topbottom-null">
              <div className="padding-onlytop-sm">
                <p className="margin-bottom margin-md-bottom-null">
                  <table className="no-border">
                    <tr>
                      <td width="40%" className="dd secondary-color">
                        傳 感 器 ：
                        <br />  <br /> <br />
                        自 由 度 ：
                        <br />
                        速 度：
                        <br />
                        續航里程： <br />
                        可搭載配件：
                      </td>
                      <td>
                        頭部人臉識別攝像頭×1<br />
                        胸部單目視覺攝像頭×2 <br />
                        底盤超聲波傳感器×6 *<br />
                        手臂6×2DOF(l/R) * <br />
                        手爪6×2DOF(l/R) *<br />
                        1m/s <br />
                        20KM <br />
                        噴灑器 <br />
                        置物籃、背包 <br />
                        自動發卡器等
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td>
                        <br />* 非標配項目
                      </td>
                    </tr>
                  </table>
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-4 padding-leftright-null padding-md-top">
            <div className="text padding-topbottom-null">
              <picture>
                <img
                  src={ImageBlueprint}
                  className="blueprint img-responsive"
                  alt=""
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
      {/*   END Home Wrap  */}
    </div>
    {/*   END Page Content */}
    {/*   END Main Wrap  */}
  </div>
  </Layout>
);

export default GuardPage;
